import React from 'react';
import { Box, Grid, Typography, Card, CardMedia } from '@mui/material';

const TutorialsPage = () => {
  const videos = [
    { id: '1', url: 'https://www.youtube.com/watch?v=I9upkzIfp1k', title: 'Video 1' },
    { id: '2', url: 'https://www.youtube.com/watch?v=IwolvFYwYBA', title: 'Video 2' },
    { id: '3', url: 'https://www.youtube.com/watch?v=8D6o-dTXsZA', title: 'Video 3' },
    { id: '4', url: 'https://www.youtube.com/watch?v=A2HY8z0RKp4', title: 'Video 4' },
  ];

  const transformedVideos = videos.map((video) => ({
    ...video,
    url: video.url.replace('watch?v=', 'embed/'),
  }));

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#0b1e35',
        color: '#fff',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Tutorials
      </Typography>
      <Grid container spacing={2}>
        {transformedVideos.map((video) => (
          <Grid item xs={12} sm={6} key={video.id}>
            <Card sx={{ backgroundColor: '#1a273f', padding: '16px' }}>
              <CardMedia
                component="iframe"
                src={video.url}
                title={video.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{ height: '300px', borderRadius: '8px' }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TutorialsPage;
