import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ParachuteIcon from '@mui/icons-material/Paragliding';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'; // Video icon
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile breakpoint

  // Toggle Drawer
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#0b1e35' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/img/logo.png"
              alt="Logo"
              style={{
                width: '120px',
                height: '40px',
                objectFit: 'contain',
              }}
            />
          </Box>

          {isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <appkit-button />
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexGrow: 1, marginLeft: 2, alignItems: 'center' }}>
              <Button
                component={Link}
                to="/"
                color="inherit"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <SwapHorizIcon />
                Swap
              </Button>
              <Button
                component={Link}
                to="/create-token"
                color="inherit"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <AttachMoneyIcon />
                Create Token
              </Button>
              <Button
                component={Link}
                to="/create-launchpad"
                color="inherit"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <RocketLaunchIcon />
                Create Launchpad
              </Button>
              <Button
                component={Link}
                to="/create-airdrop"
                color="inherit"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <ParachuteIcon />
                Create Airdrop
              </Button>
              <Button
                component={Link}
                to="/tutorials"
                color="inherit"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <VideoLibraryIcon />
                Tutorials
              </Button>
            </Box>
          )}

          {!isMobile && (
            <w3m-button />
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ '& .MuiDrawer-paper': { backgroundColor: '#0b1e35', color: '#fff' } }}
      >
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            <ListItem button component={Link} to="/">
              <ListItemIcon sx={{ color: '#fff' }}>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary="Swap" sx={{ color: '#fff', textTransform: 'none' }} />
            </ListItem>
            <ListItem button component={Link} to="/create-token">
              <ListItemIcon sx={{ color: '#fff' }}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Create Token" sx={{ color: '#fff', textTransform: 'none' }} />
            </ListItem>
            <ListItem button component={Link} to="/create-launchpad">
              <ListItemIcon sx={{ color: '#fff' }}>
                <RocketLaunchIcon />
              </ListItemIcon>
              <ListItemText
                primary="Create Launchpad"
                sx={{ color: '#fff', textTransform: 'none' }}
              />
            </ListItem>
            <ListItem button component={Link} to="/create-airdrop">
              <ListItemIcon sx={{ color: '#fff' }}>
                <ParachuteIcon />
              </ListItemIcon>
              <ListItemText
                primary="Create Airdrop"
                sx={{ color: '#fff', textTransform: 'none' }}
              />
            </ListItem>
            <ListItem button component={Link} to="/tutorials">
              <ListItemIcon sx={{ color: '#fff' }}>
                <VideoLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Tutorials" sx={{ color: '#fff', textTransform: 'none' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
