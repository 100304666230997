import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  Modal,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TableContainer,
} from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { useAppKitProvider, useAppKitAccount, useAppKitNetwork } from "@reown/appkit/react";
import routerABI from '../abi/pancakeSwapRouterABI.json';
import ERC20ABI from '../abi/ERC20ABI.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SwapCard = () => {
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');
  const { chainId, switchNetwork } = useAppKitNetwork()

  // State for input values and tokens
  const [topValue, setTopValue] = useState('');
  const [bottomValue, setBottomValue] = useState('0');
  const [topToken, setTopToken] = useState(null);
  const [bottomToken, setBottomToken] = useState(null);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  // Tokens and filtering state
  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Swapping
  const [isSwapping, setIsSwapping] = useState(false);

  // PancakeSwap Router contract details
  const routerAddress = process.env.REACT_APP_ROUTER;
  // Initialize ethers provider
  const providerx = new JsonRpcProvider("https://bsc-dataseed.binance.org/");
  const routerContract = new Contract("0x10ED43C718714eb63d5aA57B78B54704E256024E", routerABI, providerx);


  const fetchTokenBalance = async (tokenAddress, decimals) => {
    if (!isConnected) return '0';
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      if (tokenAddress === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
        // Fetch native token balance (e.g., BNB)
        const balance = await provider.getBalance(address);
        return formatEther(balance); // Format for native tokens like BNB
      } else {
        // Fetch ERC20 token balance
        const tokenContract = new Contract(tokenAddress, ERC20ABI, provider);
        const balance = await tokenContract.balanceOf(address);
        return formatUnits(balance, decimals);
      }
    } catch (error) {
      //console.error(`Error fetching balance for token ${tokenAddress || 'BNB'}:`, error);
      return '0';
    }
  };


  // Fetch tokens.json from the public folder
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await fetch('/tokens.json');
        const data = await response.json();
        setTokens(data);
        setFilteredTokens(data);

        // Set the first two tokens as the default tokens for the swap boxes
        if (data.length > 1) {
          setTopToken({ name: data[0].name, symbol: data[0].symbol, address: data[0].address, logo: data[0].logo });
          setBottomToken({ name: data[1].name, symbol: data[1].symbol, address: data[1].address, logo: data[1].logo });
        }
      } catch (error) {
        console.error('Error loading tokens.json:', error);
      }
    };
    fetchTokens();
  }, []);

  useEffect(() => {
     const fetchInitialBalances = async () => {
       if (topToken && bottomToken && address) {
         const topBalance = await fetchTokenBalance(topToken.address, topToken.decimals);
         const bottomBalance = await fetchTokenBalance(bottomToken.address, bottomToken.decimals);

         setTopToken((prev) => ({ ...prev, balance: topBalance }));
         setBottomToken((prev) => ({ ...prev, balance: bottomBalance }));
       }
     };
     fetchInitialBalances();
   }, [address]);

   const roundToThreeDecimals = (num) => Math.round(num * 1000) / 1000;

  // Get estimated amount from PancakeSwap
  const getSwapEstimate = async (amount) => {
    if (!topToken || !bottomToken || !amount || amount === '0') return;

    try {
      const path = [topToken.address, bottomToken.address];
      const amountIn = parseUnits(amount, topToken.decimals);

      const amountsOut = await routerContract.getAmountsOut(amountIn, path);
      const estimatedAmount = formatUnits(
        amountsOut[1],
        bottomToken.decimals
      );

      setBottomValue(estimatedAmount);
    } catch (error) {
      console.error('Error fetching swap estimate:', error);
      setBottomValue('0');
    }
  };

  const handleTopValueChange = (value) => {
    // Allow only valid decimal numbers
    const regex = /^(?!0\d)(\d+(\.\d{0,18})?|0(\.\d{0,18})?)$/;
    if (value === '' || regex.test(value)) {
      setTopValue(value);

      if (value === '') {
        setBottomValue('0'); // Reset bottom value to 0 when top is empty
      } else {
        getSwapEstimate(value); // Update the swap estimate
      }
    }
  };

  // Function to handle search input
  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowerCaseQuery = query.toLowerCase();
    const filtered = tokens.filter(
      (token) =>
        token.name.toLowerCase().includes(lowerCaseQuery) ||
        token.symbol.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredTokens(filtered);
  };

  // Function to handle token selection
  const handleTokenSelect = async(token) => {
    if (
      (selectedField === 'top' && bottomToken?.logo === token.logo) ||
      (selectedField === 'bottom' && topToken?.logo === token.logo)
    ) {
      toast.error('You cannot select the same token for both sides.');
      return;
    }

    if (selectedField === 'top') {
      setTopToken({ name: token.name, symbol: token.symbol, address: token.address, logo: token.logo });
    } else if (selectedField === 'bottom') {
      setBottomToken({ name: token.name, symbol: token.symbol, address: token.address, logo: token.logo });
    }

    let balance = '0';
    if (address) {
      balance = await fetchTokenBalance(token.address, token.decimals);
      //toast.info(balance);
    }

    if (selectedField === 'top') {
        setTopToken({
          ...token,
          balance,
        });
      } else if (selectedField === 'bottom') {
        setBottomToken({
          ...token,
          balance,
        });
      }

    // Reset input values when a coin is changed
    setTopValue('');
    setBottomValue('0');

    setSearchQuery(''); // Reset search query
    setIsModalOpen(false);
  };


  const swapToken = async () => {
    // Inetial checks
    if(chainId != 56){
        toast.error("Please switch your wallet network to BNB Smart Chain");
        switchNetwork(56);
        return;
    }
    if (!address || !topToken || !bottomToken || !topValue || topValue === '0') {
      toast.error('Please connect your wallet and enter a valid amount.');
      return;
    }

    setIsSwapping(true);

    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const myRouter = new Contract(routerAddress, routerABI, signer);
      console.log(routerAddress);

      const bnbBalance = await provider.getBalance(address);
      console.log(bnbBalance);

      const amountIn = String(parseUnits(topValue, topToken.decimals));
      const amountOutMin = 0;
      const path = [topToken.address, bottomToken.address];
      const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now
      const to = address;

      // Check and approve allowance
      const tokenContract = new Contract(topToken.address,ERC20ABI,signer);
      const allowance = await tokenContract.allowance(address, routerAddress);

      if(topToken.symbol == "BNB"){
        console.log(parseUnits(topValue));
        const tx = await myRouter.swapExactETHForTokens(
          amountOutMin,
          path,
          to,
          deadline,
          {
            value: parseUnits(topValue),
            gasLimit: 300000,
          }
        );
        toast.info('Transaction sent');
        await tx.wait();
        toast.success('Transaction confirmed:', tx);
        //toast.success('Congratulations, Swap transaction confirmed');
      }else{
        toast.info('Approving token...');
        const txa = await tokenContract.approve(routerAddress, parseUnits(topValue, topToken.decimals));
        await txa.wait();
        toast.success('Token approved successfully!');
        // Perform the swap
        toast.info('Swapping tokens...');
        if(bottomToken.symbol == "BNB"){
          // Swap tokens for BNB
          const tx = await myRouter.swapExactTokensForETH(
            amountIn,
            amountOutMin,
            path,
            to,
            deadline,
            {
              gasLimit: 300000,
            }
          );
          toast.info('Transaction sent');
          await tx.wait();
          toast.success('Transaction confirmed');
        }else{
          // Swap tokens for tokens
          const tx = await myRouter.swapExactTokensForTokens(
            amountIn, // Amount in
            amountOutMin, // Minimum amount out (use slippage tolerance in real app)
            path,
            address,
            deadline,
            {
              gasLimit: 300000,
            }
          );
          toast.info('Transaction sent');
          await tx.wait();
          toast.success('Transaction confirmed');
        }
      }

    } catch (error) {
      console.error('Error during swap:', error);
      toast.error('Swap failed. Please try again.');
    }

    setIsSwapping(false);
    setBottomValue('0');
    setTopValue('');
  };


  return (
    <>
      <Paper
        elevation={4}
        sx={{
          width: '500px',
          padding: '24px',
          borderRadius: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Swap
        </Typography>
        {/* Input Field 1 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, position: 'relative' }}>
          <TextField
            fullWidth
            label={topToken?.name || 'Loading...'}
            placeholder="Enter Amount"
            variant="outlined"
            value={topValue}
            onChange={(e) => handleTopValueChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setIsModalOpen(true) || setSelectedField('top')}>
                  <img
                    src={topToken?.logo}
                    alt="Token Icon"
                    style={{ width: 36, height: 36 }}
                  />
                </IconButton>
              ),
            }}
            inputProps={{
              inputMode: 'decimal', // Ensures mobile keyboards show only numbers
              pattern: '[0-9.]*', // Restricts input to numbers
            }}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: '1.8rem',
                padding: '12px',
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: '-20px',
              left: '12px',
              color: '#aaa',
            }}
          >
            Balance: {roundToThreeDecimals(topToken?.balance) || '0'} {topToken?.symbol}
          </Typography>
        </Box>
        {/* Swap Icon */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <IconButton
            sx={{
              backgroundColor: '#0d47a1',
              color: '#fff',
              '&:hover': { backgroundColor: '#1a237e' },
              width: '40px',
              height: '40px',
            }}
            onClick={() => {
              const tempToken = topToken;
              setTopToken(bottomToken);
              setBottomToken(tempToken);
              setTopValue('0');
              setBottomValue('0');
            }}
          >
            <SwapVertIcon sx={{ fontSize: '1.2rem' }} />
          </IconButton>
        </Box>
        {/* Input Field 2 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, position: 'relative' }}>
          <TextField
            fullWidth
            label={bottomToken?.name || 'Loading...'}
            placeholder="Estimated Amount"
            variant="outlined"
            value={bottomValue}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={() => setIsModalOpen(true) || setSelectedField('bottom')}>
                  <img
                    src={bottomToken?.logo}
                    alt="Token Icon"
                    style={{ width: 36, height: 36 }}
                  />
                </IconButton>
              ),
            }}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: '1.8rem',
                padding: '12px',
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: '-20px',
              left: '12px',
              color: '#aaa',
            }}
          >
            Balance: {roundToThreeDecimals(bottomToken?.balance) || '0'} {bottomToken?.symbol}
          </Typography>
        </Box>
        {/* Swap Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '1.2rem', padding: '16px', borderRadius: '10px' }}
          onClick={swapToken}
          disabled={isSwapping || !address || !topValue || topValue === '0'}
        >
          {isSwapping ? 'Swapping...' : 'Swap'}
        </Button>
      </Paper>

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSearchQuery(''); // Clear search when modal is closed
          setFilteredTokens(tokens); // Reset filtered tokens
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Select a Token
          </Typography>
          <TextField
            fullWidth
            placeholder="Search for a token"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              marginBottom: '16px',
            }}
          />
          <TableContainer
            sx={{
              maxHeight: 450,
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Logo</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Symbol</TableCell> {/* Added Symbol Column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTokens.map((token, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleTokenSelect(token)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#333', color: '#fff' },
                    }}
                  >
                    <TableCell align="left">
                      <Avatar src={token.logo} alt={token.name} />
                    </TableCell>
                    <TableCell align="left">{token.name}</TableCell>
                    <TableCell align="left">{token.symbol}</TableCell> {/* Display Token Symbol */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
};

export default SwapCard;
