import React, { useEffect, useState } from 'react';
import { Paper, Link, Modal, Typography, Box, TextField, Button } from '@mui/material';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { useAppKitProvider, useAppKitAccount, useAppKit, useAppKitNetwork } from "@reown/appkit/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateTokenPage = () => {

  const tokenCreator = process.env.REACT_APP_TOKEN;
  const abi = [{"constant":false,"inputs":[{"internalType":"address","name":"cOwner","type":"address"},{"internalType":"string","name":"name","type":"string"},{"internalType":"string","name":"symbol","type":"string"},{"internalType":"uint256","name":"totalSupply","type":"uint256"}],"name":"createToken","outputs":[{"internalType":"bool","name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"inputs":[{"internalType":"address payable","name":"_theOwner","type":"address"},{"internalType":"uint256","name":"fee","type":"uint256"}],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"constant":false,"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},{"constant":true,"inputs":[],"name":"getNewToken","outputs":[{"internalType":"contract TOKEN","name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"newToken","outputs":[{"internalType":"contract TOKEN","name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"}];

  const fee = "20000000000000000";

    // 4. Use modal hook
  const { open } = useAppKit();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');
  const { chainId, switchNetwork } = useAppKitNetwork();
  const [deployedToken, setDeployedToken] = useState('');
  const [createTokenButton, setCreateTokenButton] = useState('Create Token');
  const [isDeployeding, setIsDeployeding] = useState(false);

  const [formData, setFormData] = useState({
    nameText: '',
    symbolText: '',
    supplyText: '',
    ownerText: '',
  });

  const [errors, setErrors] = useState({
    nameText: false,
    symbolText: false,
    supplyText: false,
    ownerText: false,
  });

  let myTokenContract = '';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function timeout() {
    return new Promise( res => setTimeout(res, 5000) );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation for specific fields
    if (name === 'supplyText' && !/^[0-9]*$/.test(value)) {
      return; // Prevent non-numeric input for supplyText
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const isEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address); // Regex for Ethereum address
  };


  const handleSubmit = async(e) => {
    e.preventDefault();

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        newErrors[key] = 'This field is required';
      } else if (key === 'supplyText' && (!/^[1-9][0-9]*$/.test(formData[key]) || parseInt(formData[key], 10) <= 1)) {
        newErrors[key] = 'Must be an integer greater than 1 and not start with 0';
      } else if (key === 'ownerText' && !isEthereumAddress(formData[key])) {
        newErrors[key] = 'Invalid wallet address';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try{
          if(isConnected){
            setIsDeployeding(true);
            setCreateTokenButton('Please Wait...');

            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(tokenCreator, abi, signer);

            const bnbBalance = await provider.getBalance(address);
            if(bnbBalance <= Number(fee)){
              toast.error("Not enough BNB balance.");
              setIsDeployeding(false);
              setCreateTokenButton('Create Token');
              return;
            }

            console.log("Intialize payment");
            let newToken = await contract.createToken(formData.ownerText, formData.nameText, formData.symbolText, formData.supplyText, {value: fee});

            let tempTokenContract = await contract.getNewToken();
            myTokenContract = tempTokenContract;
            while(tempTokenContract === myTokenContract){
              myTokenContract = await contract.getNewToken();
              await timeout();
            }

            if(newToken){
              setDeployedToken(myTokenContract);
              await timeout();
              console.log(myTokenContract);
              handleOpenModal();
            }else{
              toast.error("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee (fee = 0.02 BNB).");
            }
          }else{
            open();
          }
        }catch(err){
          toast.error("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee (fee = 0.02 BNB).");
          console.log(err);
          setIsDeployeding(false);
          setCreateTokenButton('Create Token');
        }
    }
    setIsDeployeding(false);
    setCreateTokenButton('Create Token');
  };

  return (
    <Paper
      elevation={4}
      sx={{
        width: '500px',
        padding: '24px',
        borderRadius: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Create Token
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Token Name"
        name="nameText"
        value={formData.nameText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        error={!!errors.nameText}
        helperText={errors.nameText || ''}
      />
      <TextField
        fullWidth
        label="Token Symbol"
        name="symbolText"
        value={formData.symbolText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        error={!!errors.symbolText}
        helperText={errors.symbolText || ''}
      />
      <TextField
        fullWidth
        label="Total Supply"
        name="supplyText"
        value={formData.supplyText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        error={!!errors.supplyText}
        helperText={errors.supplyText || ''}
      />
      <TextField
        fullWidth
        label="Owner Wallet"
        name="ownerText"
        value={formData.ownerText}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        error={!!errors.ownerText}
        helperText={errors.ownerText || ''}
      />
      <Button type="submit" disabled={isDeployeding} variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
        {createTokenButton}
      </Button>
    </Box>

    <Modal
      open={isModalOpen}
      onClose={() => {}} // Prevent background click from closing the modal
      disableEscapeKeyDown
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 450,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Token Details
        </Typography>

        {/* Token Name */}
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Token Name:</strong> {formData.nameText || 'N/A'}
        </Typography>

        {/* Token Symbol */}
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Token Symbol:</strong> {formData.symbolText || 'N/A'}
        </Typography>

        {/* Contract Address */}
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Contract:</strong>{' '}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
            <Link
              href={`https://bscscan.com/token/${deployedToken}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              color="primary"
            >
              {deployedToken}
            </Link>

        </Typography>

        {/* Close Button */}
        <Button variant="contained" fullWidth onClick={handleCloseModal}>
          Close
        </Button>
      </Box>
    </Modal>

    <ToastContainer position="top-right" autoClose={3000} />
    </Paper>
  );
};

export default CreateTokenPage;
