import React, { useState } from 'react';
import { Paper, Modal, Link, Typography, TextField, Button, Box } from '@mui/material';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { useAppKitProvider, useAppKitAccount, useAppKit, useAppKitNetwork } from "@reown/appkit/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateAirdropPage = () => {

  const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"_seller","type":"address"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"airdropAmount","type":"uint256"},{"internalType":"uint256","name":"fees","type":"uint256"},{"internalType":"uint256","name":"decimals","type":"uint256"}],"name":"createAirdrop","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"getNewAirdrop","outputs":[{"internalType":"contract Airdrop","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newAirdrop","outputs":[{"internalType":"contract Airdrop","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
  const fee = "50000000000000000";

  let myAirdropContract = "";
  let tempAirdropContract = "";

  // Wallet AppKit
  const { open } = useAppKit();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');
  const { chainId, switchNetwork } = useAppKitNetwork();

  const [createButtonText, setCreateButtonText] = useState("Create Airdrop");
  const [isLoading, setIsLoading] = useState(false);
  const [deployedContract, setDeployedContract] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  let myPresaleContract = "";
  let tempPresaleContract = "";
  const contractAddress = process.env.REACT_APP_AIRDROP;

  const [formData, setFormData] = useState({
    ownerWallet: '',
    tokenContract: '',
    tokenSymbol: '',
    tokenDecimal: '',
    airdropAmount: '',
    feeInBNB: '',
  });

  const [errors, setErrors] = useState({
    ownerWallet: false,
    tokenContract: false,
    tokenSymbol: false,
    tokenDecimal: false,
    airdropAmount: false,
    feeInBNB: false,
  });

  // Ethereum address validation
  const isEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address); // Regex for Ethereum address
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function timeout() {
    return new Promise( res => setTimeout(res, 5000) );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Field-specific validation
    if (name === 'tokenDecimal' || name === 'airdropAmount') {
      if (!/^\d*$/.test(value)) return; // Allow only integers
    }
    if (name === 'feeInBNB' && !/^\d*\.?\d*$/.test(value)) {
      // Allow only decimals
      return;
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };


  const handleSubmit = async(e) => {
    e.preventDefault();

    // Validate fields
    const newErrors = {};
    if (!isEthereumAddress(formData.ownerWallet)) {
      newErrors.ownerWallet = 'Invalid wallet address';
    }
    if (!isEthereumAddress(formData.tokenContract)) {
      newErrors.tokenContract = 'Invalid wallet address';
    }
    if (!formData.tokenSymbol.trim()) {
      newErrors.tokenSymbol = 'This field is required';
    }
    if (!formData.tokenDecimal.trim() || parseInt(formData.tokenDecimal, 10) < 0) {
      newErrors.tokenDecimal = 'Must be a valid integer';
    }
    if (!formData.airdropAmount.trim() || parseInt(formData.airdropAmount, 10) < 0) {
      newErrors.airdropAmount = 'Must be a valid integer';
    }
    if (!formData.feeInBNB.trim() || isNaN(parseFloat(formData.feeInBNB))) {
      newErrors.feeInBNB = 'Must be a valid decimal';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try{
          let newFeeText = String(parseFloat(formData.feeInBNB) * 1000000000000000000);
          if(isConnected){
            setIsLoading(true);
            setCreateButtonText("Please Wait...");

            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            const bnbBalance = await provider.getBalance(address);
            if(bnbBalance <= Number(fee)){
              toast.error("Not enough BNB balance.");
              setCreateButtonText("Create Airdrop");
              setIsLoading(false);
            }

            console.log("Intialize payment");
            let newAirdrop = await contract.createAirdrop(formData.ownerWallet, formData.tokenContract, formData.airdropAmount, newFeeText, formData.tokenDecimal, {value: fee});
            tempAirdropContract = await contract.getNewAirdrop();
            myAirdropContract = tempAirdropContract;

            while(tempAirdropContract === myAirdropContract){
              myAirdropContract = await contract.getNewAirdrop();
              await timeout();
            }
        if(newAirdrop){
          setDeployedContract(myAirdropContract);
          await timeout();
          console.log(myAirdropContract);
          handleOpenModal();
        }else{
          toast.error("Something wrong, Some fields maybe missing.");
        }
          }
        }catch(err){
          toast.error("Something wrong, try again.");
          console.log(err);
        }
    }
    setCreateButtonText("Create Airdrop");
    setIsLoading(false);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        margin: '20px auto',
        padding: '20px',
        width: '90%',
        maxWidth: '600px',
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Create Airdrop
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Owner Wallet"
          name="ownerWallet"
          value={formData.ownerWallet}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.ownerWallet}
          helperText={errors.ownerWallet || ''}
        />
        <TextField
          fullWidth
          label="Token Contract"
          name="tokenContract"
          value={formData.tokenContract}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.tokenContract}
          helperText={errors.tokenContract || ''}
        />
        <TextField
          fullWidth
          label="Token Symbol"
          name="tokenSymbol"
          value={formData.tokenSymbol}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.tokenSymbol}
          helperText={errors.tokenSymbol || ''}
        />
        <TextField
          fullWidth
          label="Token Decimal"
          name="tokenDecimal"
          value={formData.tokenDecimal}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.tokenDecimal}
          helperText={errors.tokenDecimal || ''}
        />
        <TextField
          fullWidth
          label="Airdrop Amount"
          name="airdropAmount"
          value={formData.airdropAmount}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.airdropAmount}
          helperText={errors.airdropAmount || ''}
        />
        <TextField
          fullWidth
          label="Fee in BNB"
          name="feeInBNB"
          value={formData.feeInBNB}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          error={!!errors.feeInBNB}
          helperText={errors.feeInBNB || ''}
        />
        <Button type="submit" disabled={isLoading} variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
          {createButtonText}
        </Button>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => {}} // Prevent background click from closing the modal
        disableEscapeKeyDown
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Congratulations
          </Typography>

          {/* Token Name */}
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Your airdrop contract has beed created</strong>
          </Typography>

          {/* Token Symbol */}
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Step1: Copy the contract address and save it in text file</strong>
          </Typography>

          {/* Contract Address */}
          <Typography variant="body1" sx={{ mb: 4 }}>
              <Link
                href={`https://bscscan.com/address/${deployedContract}`}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                color="primary"
              >
                {deployedContract}
              </Link>
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Step2: Download the airdrop script and edit the file data.json</strong>
          </Typography>

          <Typography variant="body1" sx={{ mb: 4 }}>
            <strong>
              <Link
                href="https://bit.ly/Airdrop-UI"
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                color="primary"
              >
                Download Airdrop Script
              </Link>
            </strong>
          </Typography>

          {/* Close Button */}
          <Button variant="contained" fullWidth onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>

      <ToastContainer position="top-right" autoClose={3000} />
    </Paper>
  );
};

export default CreateAirdropPage;
