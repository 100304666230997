import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, Container, Box, ThemeProvider, CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CreateTokenPage from './pages/CreateTokenPage';
import CreateLaunchpadPage from './pages/CreateLaunchpadPage';
import CreateLaunchpadPlusPage from './pages/CreateLaunchpadPlusPage';
import CreateAirdropPage from './pages/CreateAirdropPage';
import TutorialsPage from './pages/TutorialsPage';
import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { bsc, sepolia, polygon } from '@reown/appkit/networks'

// 1. Get projectId
  const projectId = process.env.REACT_APP_PROJECT_ID;

  // 2. Set the networks
  const networks = [bsc];

  // 3. Create a metadata object - optional
  const metadata = {
    name: '0xTools',
    description: '0xTools Platform',
    url: 'https://tools.0xfactory.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }

  // 4. Create a AppKit instance
  createAppKit({
    adapters: [new EthersAdapter()],
    networks,
    metadata,
    projectId,
    features: {
      analytics: true, // Optional - defaults to your Cloud configuration
      email: false, // default to true
      socials:false
    }
  })

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#aaaaaa',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: '/background.jpg', // Replace with your image path
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          color: '#ffffff', // Ensure text is readable
        },
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1a237e, #0d47a1)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
      <Router>
        <Navbar />
        <Container
          sx={{
            mt: 5,
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/create-token" element={<CreateTokenPage />} />
            <Route path="/create-launchpad" element={<CreateLaunchpadPlusPage />} />
            <Route path="/create-airdrop" element={<CreateAirdropPage />} />
            <Route path="/tutorials" element={<TutorialsPage />} />
          </Routes>
          </Container>
        </Router>
      <br />
      <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
