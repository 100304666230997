import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#0b1e35',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        borderTop: '1px solid #333',
      }}
    >
      {/* Left Side: Copyright Text */}
      <Typography variant="body2" sx={{ fontSize: '14px' }}>
        © {new Date().getFullYear()} 0xFactory.com. All Rights Reserved.
      </Typography>

      {/* Right Side: Social Media Links */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <IconButton
          component="a"
          href="https://twitter.com/0xFactoryCom"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#fff' }}
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://t.me/ZeroxFactory"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#fff' }}
        >
          <TelegramIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.facebook.com/0xFactory"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#fff' }}
        >
          <FacebookIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
