import React from 'react';
import SwapCard from '../components/SwapCard';
import Footer from '../components/Footer';
import { Box } from '@mui/material';

const HomePage = () => {
  return (
        <SwapCard />
  );
};

export default HomePage;
